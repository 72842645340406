<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="box">
      <div class="neir">
        <div class="tit">404</div>
        <p>Sorry,Page Not Find.</p>
        <el-button @click="btnClick">Back to home</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
export default {
  methods: {
    btnClick() {
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_404") {
        this.$router.replace("/m_404");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/404") {
        this.$router.replace("/404");
      }
    }
  },
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 1030px;
  margin: 40px auto 0;
  .neir {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #12334c;
    .tit {
      font-size: 200px;
      font-weight: bold;
    }
    p {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 60px;
    }
    .el-button {
      width: 145px;
      height: 42px;
      border-radius: 7px;
      background: #12334c;
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>